import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Header from "./components/constants/header";
import Footer from "./components/constants/Footer";
import Index from "./components/constants/Index";
import Aboutus from "./components/Contents/About/About";
import Vision from "./components/Contents/About/Vision";
import Chairman from "./components/Contents/About/Chairman";
import Principle from "./components/Contents/About/Principle";
import Amc from "./components/Contents/About/Amc";
import Eligibility from "./components/Contents/Admission/Eligibility";
import Co from "./components/Contents/Admission/Co";
import Rd from "./components/Contents/Admission/Rd";
import Computer from "./components/Contents/Course/Engineering/Computer/Computer";
import Aboutcomp from "./components/Contents/Course/Engineering/Computer/Aboutcomp";
import Pospo from "./components/Contents/Course/Engineering/Computer/Pospo";
import Labo from "./components/Contents/Course/Engineering/Computer/Labo";
import Mechanical from "./components/Contents/Course/Engineering/Mechanical/Mechanical";
import Aboutmech from "./components/Contents/Course/Engineering/Mechanical/Aboutmech";
import Mechpospo from "./components/Contents/Course/Engineering/Mechanical/Mechpospo";
import Mechlabo from "./components/Contents/Course/Engineering/Mechanical/Mechlabo";
import Extc from "./components/Contents/Course/Engineering/Extc/Extc";
import Aboutextc from "./components/Contents/Course/Engineering/Extc/Aboutextc";
import Extcpospo from "./components/Contents/Course/Engineering/Extc/Extcpospo";
import Extclabo from "./components/Contents/Course/Engineering/Extc/Extclabo";
import Civil from "./components/Contents/Course/Engineering/Civil/Civil";
import Aboutcivil from "./components/Contents/Course/Engineering/Civil/Aboutcivil";
import Civilpospo from "./components/Contents/Course/Engineering/Civil/Civilpospo";
import Civillabo from "./components/Contents/Course/Engineering/Civil/Civillabo";
import Dcivil from "./components/Contents/Course/Diploma/Dcivil/Dcivil";
import Dcivilpospo from "./components/Contents/Course/Diploma/Dcivil/Dcivilpospo";
import Dcivillabo from "./components/Contents/Course/Diploma/Dcivil/Dcivillabo";
import AboutDcivil from "./components/Contents/Course/Diploma/Dcivil/AboutDcivil";
import Dmech from "./components/Contents/Course/Diploma/Dmech/Dmech";
import AboutDmech from "./components/Contents/Course/Diploma/Dmech/AboutDmech";
import Dmechpospo from "./components/Contents/Course/Diploma/Dmech/Dmechpospo";
import Dmechlabo from "./components/Contents/Course/Diploma/Dmech/Dmechlabo";
import Bsccs from "./components/Contents/Course/Bsc/Cs/Bsccs";
import Aboutcs from "./components/Contents/Course/Bsc/Cs/Aboutcs";
import Bscpospo from "./components/Contents/Course/Bsc/Cs/Bscpospo";
import Bscit from "./components/Contents/Course/Bsc/It/Bscit";
import Aboutbscit from "./components/Contents/Course/Bsc/It/Aboutbscit";
import Bscitpospo from "./components/Contents/Course/Bsc/It/Bscitpospo";
import AboutCampus from "./components/Contents/Infrastructure/AboutCampus";
import CampusPics from "./components/Contents/Infrastructure/CampusPics";
import CentralLib from "./components/Contents/Facilities/CentralLib";
import Gym from "./components/Contents/Facilities/Gym";
import Transport from "./components/Contents/Facilities/Transport";
import Internet from "./components/Contents/Facilities/Internet";
import Tarang from "./components/Contents/Events/Tarang";
import Sports from "./components/Contents/Events/Sports";
import Rex from "./components/Contents/Events/Rex";
import Convocation from "./components/Contents/Events/Convocation";
import ScienceEx from "./components/Contents/Events/ScienceEx";
import Shivjayanti from "./components/Contents/Events/Shivjayanti";
import Desk from "./components/Contents/T&P/Desk";
import Mou from "./components/Contents/T&P/Mou";
import MajorR from "./components/Contents/T&P/MajorR";
import Helpline from "./components/Contents/Exam/helpline";
import Timetable from "./components/Contents/Exam/Timetable";
import Circular from "./components/Contents/Exam/Circular";
import Contact from "./components/Contents/Contact/Contact";
import Naac from "./components/view/Naac";
import Test from "./components/tests/test";
import Table from "./components/Contents/T&P/PlacementTable";
import Calendar from "./components/Contents/T&P/Calendar";
import Preloader from "./components/view/Preloader";
import Fstructure from "./components/Contents/Admission/Fstructure";
import Searchicon from "./components/view/Searchicon";
import Syllabus from "./components/Contents/T&P/Syllabus";
import Committe from "./components/view/Statutary/Committe";
import Aicte from "./components/view/Statutary/Aicte";
import Feedback from "./components/view/Statutary/Feedback";
import Mdisclosure from "./components/view/Statutary/Mdisclosure";
import Agnipath from "./components/view/Statutary/Agnipath";
import Almaconnect from "./components/Contents/Alumni/Almaconnect";
import Payment from "./components/view/Payment";
import ScrollTop from "./components/view/ScrollTop";
import OnlineG from "./components/view/Statutary/onlineG";
import Popup from "./components/view/Popup";
import Login from "./components/view/dashboard/Login";
import Dashboard from "./components/view/dashboard/Dashboard";
import AuthProvider from "./components/view/dashboard/AuthProvider";
import ProtectedRoute from "./components/view/dashboard/ProtectedRoute";
import Dashpdf from "./components/view/dashboard/Dashpdf";
import Dashnews from "./components/view/dashboard/Dashnews";
import Proudalumni from "./components/Contents/Alumni/Proudalumni";
import FeeApproP from "./components/view/Statutary/FeeApproP";

function App() {
  return (
    <div className="bg-all">
      <GoogleOAuthProvider clientId="20638390628-vlrn77r6ss2r7mi0hfectms4038df0ic.apps.googleusercontent.com">
        <AuthProvider>
          <Router>
            <Header />
            <Searchicon />
            <ScrollTop />
            <Preloader />
            <Popup />
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/test" element={<Test />} />

              <Route path="/Naac" element={<Naac />} />

              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
            path="/dashpdf"
            element={
              <ProtectedRoute>
                <Dashpdf />
              </ProtectedRoute>
            }
          />
        <Route
            path="/dashnews"
            element={
              <ProtectedRoute>
                <Dashnews />
              </ProtectedRoute>
            }
          />


              {/* About */}
              <Route path="/Aboutus" element={<Aboutus />} />
              <Route path="/Vision" element={<Vision />} />
              <Route path="/Chairman" element={<Chairman />} />
              <Route path="/Principle" element={<Principle />} />
              <Route path="/Amc" element={<Amc />} />

              {/* statutary  */}
              <Route path="/Committee" element={<Committe />} />
              <Route path="/OnlineG" element={<OnlineG />} />
              <Route path="/AICTE" element={<Aicte />} />
              <Route path="/AICTE_feedback" element={<Feedback />} />
              <Route path="/Mdisclosure" element={<Mdisclosure />} />
              <Route path="/FeeAproPro" element={<FeeApproP />} />
              <Route path="/UAY" element={<Agnipath />} />

              {/* Admission */}
              <Route path="/Eligibility" element={<Eligibility />} />
              <Route path="/Co" element={<Co />} />
              <Route path="/Rd" element={<Rd />} />
              <Route path="/feestructure" element={<Fstructure />} />

              {/* payment  */}
              <Route path="/Payments" element={<Payment />} />
              {/* Courses  */}

              {/* degree  */}
              {/* Computer  */}
              <Route path="/Computer" element={<Computer />} />
              <Route path="/Aboutcomp" element={<Aboutcomp />} />
              <Route path="/Pospo" element={<Pospo />} />
              <Route path="/Laboratory" element={<Labo />} />

              {/* Mechanical  */}
              <Route path="/Mechanical" element={<Mechanical />} />
              <Route path="/Aboutmech" element={<Aboutmech />} />
              <Route path="/Mechpospo" element={<Mechpospo />} />
              <Route path="/Mechlabo" element={<Mechlabo />} />

              {/* Extc  */}
              <Route path="/Extc" element={<Extc />} />
              <Route path="/Aboutextc" element={<Aboutextc />} />
              <Route path="/Extcpospo" element={<Extcpospo />} />
              <Route path="/Extclabo" element={<Extclabo />} />

              {/* Civil  */}
              <Route path="/Civil" element={<Civil />} />
              <Route path="/Aboutcivil" element={<Aboutcivil />} />
              <Route path="/Civilpospo" element={<Civilpospo />} />
              <Route path="/Civillabo" element={<Civillabo />} />

              {/* Diploma  */}
              {/* civil  */}
              <Route path="/Dcivil" element={<Dcivil />} />
              <Route path="/AboutDcivil" element={<AboutDcivil />} />
              <Route path="/Dcivilpospo" element={<Dcivilpospo />} />
              <Route path="/Dcivillabo" element={<Dcivillabo />} />

              {/* Mechnical  */}
              <Route path="/Dmechanical" element={<Dmech />} />
              <Route path="/AboutDmech" element={<AboutDmech />} />
              <Route path="/Dmechpospo" element={<Dmechpospo />} />
              <Route path="/Dmechlabo" element={<Dmechlabo />} />

              {/* BSc  */}
              {/* CS  */}
              <Route path="/Bsccs" element={<Bsccs />} />
              <Route path="/Aboutcs" element={<Aboutcs />} />
              <Route path="/Bscpospo" element={<Bscpospo />} />

              {/* It  */}
              <Route path="/Bscit" element={<Bscit />} />
              <Route path="/Aboutbscit" element={<Aboutbscit />} />
              <Route path="/Bscitpospo" element={<Bscitpospo />} />

              {/* course end  */}

              {/* Infrastructure  */}
              <Route path="/AboutCampus" element={<AboutCampus />} />
              <Route path="/CampusPics" element={<CampusPics />} />

              {/* Facilities  */}
              <Route path="/CentralLib" element={<CentralLib />} />
              <Route path="/Gym" element={<Gym />} />
              <Route path="/Transport" element={<Transport />} />
              <Route path="/Internet" element={<Internet />} />

              {/* Alumni  */}
              <Route path="/AlmaConnect" element={<Almaconnect />} />
              <Route path="/pAlumni" element={<Proudalumni />} />

              {/* Events  */}
              <Route path="/Tarang" element={<Tarang />} />
              <Route path="/Sports" element={<Sports />} />
              <Route path="/Rex&metropulse" element={<Rex />} />
              <Route path="/Convocation" element={<Convocation />} />
              <Route path="/ScienceExhibition" element={<ScienceEx />} />
              <Route path="/Shivjayanti" element={<Shivjayanti />} />

              {/* tranning and Placements  */}
              <Route path="/Syllabus" element={<Syllabus />} />
              <Route path="/Desk" element={<Desk />} />
              <Route path="/MOU" element={<Mou />} />
              <Route path="/MajorRecruiters" element={<MajorR />} />
              <Route path="/Table" element={<Table />} />
              <Route path="/Pcalender" element={<Calendar />} />

              {/* Examination  */}
              <Route path="/helplinenumbers" element={<Helpline />} />
              <Route path="/Timetable" element={<Timetable />} />
              <Route path="/Circular" element={<Circular />} />

              {/* Contact  */}
              <Route path="/Contact" element={<Contact />} />
            </Routes>
            <Footer />
          </Router>
        </AuthProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
