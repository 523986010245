import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
function Statutary() {

  function scroll (){
    window.scrollTo(0,0);
  }
  return (
<section className='flex justify-center items-center font-change mb-[4rem]'>
<motion.div class="lg:w-[27rem] lg:h-[21rem] md:w-[25.5rem] md:h-[20rem] w-[19.5rem] h-[19rem] m-6 mt-[5rem] lg:m-0 p-1  bg-blue-100 lg:px-4 md:py-6 rounded-lg shadow-xl"
    initial={{ y:-100,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 2 }}
    >
      <div class="de-header ml-2">
        <h3 class=" sm:text-[2em] text-[1.8rem] font-semibold">Statutory <span class="text-blue-600">Information</span></h3>
        <div class="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
      </div>
        <ul className='text-center space-y-3 mt-2'>

        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/Committee">Committees</Link></li>
        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/OnlineG">Online Grievance Redressal Portal</Link></li>
        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/AICTE">AICTE EOA</Link></li>
        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/AICTE_feedback">AICTE Feedback Facility</Link></li>
        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/Mdisclosure">MANDATORY DISCLOSURE</Link></li>        
        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/FeeAproPro">FEE APPROVAL PROPOSAL</Link></li>
        <li><Link onClick={scroll} className="text-red-600 hover:text-blue-900 text-sm md:text-base " to="/UAY">UGC's Agnipath Yojana</Link></li>

        </ul>
    </motion.div>
</section>
  )
}

export default Statutary